@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3efe7;
}

.container {
  background-color: #fff;
  padding: 50px;
  border-radius: 12px;
  max-width: 700px;
  margin: 0px auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.header {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.radio-input {
  margin-right: 8px;
}

.submit-button {
  padding: 12px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #333;
}

.popup {
  text-align: center;
}

h2 {
  margin-bottom: 15px;
  text-align: center;
}

.continue-button {
  display: block;
  margin: 15px auto;
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  width: fit-content;
}

.continue-button:hover {
  background-color: #444;
}

.whatsapp-input-container {
  display: flex;
  gap: 10px;
}

.country-code-dropdown {
  width: 80px;
}

.whatsapp-input {
  flex-grow: 1;
  width: 100%;
}

.input-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 10px;
}

.whatsapp-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
}

.country-code-dropdown {
  width: 150px;
}

.input-field {
  height: 40px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50%;
}

select.input-field {
  width: 100%;
}


input.input-field {
  width: 250px;
}

.country-code-dropdown,
.whatsapp-input {
  height: 30px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown-search-input {
  height: 40px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #161525;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #161525;
}

.input-group {
  position: relative;
}

.info-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #161525;
  color: white;
  font-size: 12px;
  margin-left: 5px;
  position: relative;
}

.info-tooltip {
  display: none;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  min-width: 200px;
}

.info-button:hover .info-tooltip {
  display: block;
  opacity: 1;
}


.onboarding-options {
  display: flex;
  justify-content: center;
  align-items: center;
  /*gap: 20px;*/
  /* Uncomment the height if needed for full viewport centering */
  /* height: 50px;*/
}

.onboarding-options .card {
  width: 20%;
  height: auto;
  /*max-width: 300px;*/
  /*padding: 5px 5px; !* Reduced padding for a more compact look *!*/
  border-radius: 10px;
  text-align: center;
  color: #fefefe;
  background: linear-gradient(135deg, #3d3b3b, #2f3338); /* Enhanced gradient */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Modern shadow */
  font-size: 1rem;
  font-weight: 500;
  transition: transform 0.2s ease, box-shadow 0.2s ease, background 0.2s ease;
  cursor: pointer;
}

.onboarding-options .card:hover {
  transform: translateY(-5px);
  background: linear-gradient(135deg, #484b50, #3b4045); /* Slightly darker hover effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
}

/* Mobile view adjustments */
@media (max-width: 767px) {
  .onboarding-options {
    flex-direction: column;
    /*gap: 15px;*/
  }

  .onboarding-options .card {
    text-align: center;
    width: 30%;
    max-width: 100%;
    padding: 3px 3px; /* Further reduced padding for compact mobile view */
    font-size: 0.9rem;
  }
  .onboarding-options .card h3 {
    margin: 10px;
    font-size: 1.2rem;
  }
}




.onboarding-options .card h3 {
  margin: 10px;
  font-size: 1.2rem;
}

.onboarding-options .card p {
  font-size: 0.9rem;
  opacity: 0.85;
}


/* Optional: Styling for text inside the card */




.recommended {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.recommended:hover {
  border-color: #8CE9B5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.onboarding-options .label {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4081;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

h2 {
  margin: 10px 0;
  font-size: 24px;
}

p {
  color: #666666;
  font-size: 16px;
}

.head {
  text-align: center;
}

.explore {
  border: 2px solid #555;
}

.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /*margin: 20px;*/
  /*padding: 10px;*/
}

.logo {
  width: 200px;
  height: auto;
  margin: 20px;
}

.text-white {
  color: #ffffff;
}

/* for mobile screens */
@media (max-width: 768px) {

  .select.input-field {
    width: 100px;
  }

  .onboarding-container{
    padding: 10px;
  }

  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
  }

  .logo-card-container {
    flex-direction: column;
  }

  .logo-container {
    /*margin-right: 0;*/
    /*margin-bottom: 0px;*/
    display: flex;
    justify-content: center;
  }

  .logo {
    width: 150px;
    height: auto;
    margin: 10px;
  }

  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }

  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.permission-denied-container {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  color: #721c24;
}

.permission-denied-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.permission-denied-message {
  font-size: 16px;
  max-width: 600px;
}

.icon-container {
  margin-bottom: 20px;
}

.icon-container svg {
  color: #ff6b6b;
  width: 64px;
  height: 64px;
}

.permission-denied-card {
  padding: 30px;
  text-align: center;
}


/* popup styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 500px;
}

.subtext {
  font-size: 0.9em;
  color: #666;
  margin-top: 10px;
}

.popup-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.button-accept,
.button-cancel {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.button-accept {
  background-color: #161525;
  color: white;
}

.button-cancel {
  background-color: #ffffff;
  color: #161525;
  border: 1px solid #0B57D0;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2; /* Adjust the opacity for the fade-out effect */
  }
  100% {
    opacity: 1;
  }
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust the gap as needed */
}
.form-group{
  width: 50%;
}

.input-group {
  flex: 1;
  min-width: 200px; /* Adjust the minimum width as needed */
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.form-and-gif {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
  margin-top: 20px; /* Add space for the logo */
  gap: 70px; /* Add gap between form and GIF */
}

.onboarding-form {
  flex: 1; /* Form takes up 2/3 of the space */
  /* padding: 20px; */
  width: 100%;
  box-sizing: border-box;
  /* Add any additional styling for the form */
}

.gif-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 12px; */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* .gif-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
} */

.intro-text {
  text-align: center;
  margin-bottom: 15px;
}

.intro-text h3 {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
  margin: 0 0 10px 0;
}

.intro-text h4 {
  font-size: 16px;
  font-weight: normal;
  color: #1b1c1c;
  margin: 0;
}

.onboarding-gif {
  margin-top: 10px;
  max-width: 80%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 10px;
}

@media (max-width: 768px) {
  /* .form-and-gif {
    flex-direction: column;
    gap: 10px;
  } */

  .gif-container {
    display: none;
  }
}


/* Text Popup */
.textPopup {
  color: #4180ea;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
  align-self: flex-start;
  /*padding-left: -10px;*/
  margin-left: 35%;
  display: inline-block;
  transition: color 0.3s ease, box-shadow 0.3s ease; /* Added transition for smooth hover effect */
  text-decoration:none;
  margin-top: -8px;
}

.textPopup:hover {
  color: #023ea3; /* Darker blue on over */
  text-decoration: underline;
  /*background: transparent;*/
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}

/* For "Connect with Google" button hover effect */
.google-btn {
  background-color: #ffffff;
  border-radius: 20px;
  color: #333; /* Darker text for better contrast */
  font-size: 16px;
  padding: 12px 20px; /* More padding for button-like appearance */
  border: 1px solid #333; /* Darker border */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto; /* Adjust width to content for button-like look */
  min-width: 200px; /* Set a minimum width for consistency */
  height: 48px; /* Standard height for a button */
  margin: 20px 0;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow */
  position: relative;
}

.google-btn:hover {
  background-color: #353333; /* Darker background on hover */
  color: #ffffff; /* White text on hover for better readability */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Elevated shadow on hover */
}

/* Loader Style inside the button */
.google-btn .spinner {
  pointer-events: none;
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px; /* Space between spinner and text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

