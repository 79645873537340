.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 50%; /* Set width to 80% of the screen for responsiveness */
    max-width: 500px; /* Limit the maximum width */
}

.modal-content button {
    margin-top: 10px;
}
