.success-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  font-family: 'Figtree', Arial, sans-serif;
}

.success-icon {
  margin-bottom: 20px;
  color: #34a853;
}

.success-text {
  font-size: 16px;
  color: #666;
}

.success-btn {
  background-color: #161525;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
}

.success-btn:hover {
  background-color: #161525;
}

.success-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  position: relative;
}

.success-card {
  padding: 50px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 400px;
}

.success-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.success-logo-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.success-logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
}

.success-logo {
  width: 200px; 
}
